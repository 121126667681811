var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("section", { staticClass: "section h-mt-140" }, [
      _c("div", { staticClass: "container grid grid__col2" }, [
        _c("div", [
          _c("h3", { staticClass: "h3 form__title" }, [
            _vm._v("Profile Information"),
          ]),
          _c(
            "div",
            { staticClass: "wrapper wrapper--sm" },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "form",
                          {
                            staticClass: "form formLayout",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                handleSubmit(_vm.validateForm("form-user"))
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "formGroup" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Name",
                                    rules: { required: true },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.user.first_name,
                                                  expression: "user.first_name",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              attrs: {
                                                name: "name",
                                                type: "text",
                                                placeholder: "Full Name",
                                              },
                                              domProps: {
                                                value: _vm.user.first_name,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.user,
                                                    "first_name",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "formGroup" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Email",
                                    rules: { required: true, email: true },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.user.email,
                                                  expression: "user.email",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              attrs: {
                                                name: "email",
                                                type: "email",
                                                placeholder: "Email",
                                              },
                                              domProps: {
                                                value: _vm.user.email,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.user,
                                                    "email",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "formGroup" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Password",
                                    rules: { required: true, min: 8 },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.user.password,
                                                  expression: "user.password",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              attrs: {
                                                name: "password",
                                                type: "password",
                                                placeholder: "CURRENT PASSWORD",
                                              },
                                              domProps: {
                                                value: _vm.user.password,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.user,
                                                    "password",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]",
                                                  },
                                                ],
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [
                                                _vm._v(
                                                  "Password must be at least 8 characters"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm.updateUserError
                              ? _c("p", { staticClass: "form__error" }, [
                                  _vm._v(_vm._s(_vm.updateUserError)),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form__submit" }, [
                              _c(
                                "button",
                                { staticClass: "btn btn--primary" },
                                [_vm._v("update profile")]
                              ),
                            ]),
                            _vm.updateUserEmailSuccess
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "form__success textColorSuccess textSizeXs",
                                  },
                                  [
                                    _vm._v(
                                      "We've sent an email to " +
                                        _vm._s(_vm.user.email) +
                                        ", please confirm it's you."
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("div", [
          _c("h3", { staticClass: "h3 form__title" }, [
            _vm._v("Change Password"),
          ]),
          _c(
            "div",
            { staticClass: "wrapper wrapper--sm" },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var handleSubmit = ref.handleSubmit
                      return [
                        _c(
                          "form",
                          {
                            ref: "observer",
                            staticClass: "form formLayout",
                            attrs: { autocomplete: "off" },
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                handleSubmit(
                                  _vm.validateForm("form-change-password")
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "formGroup" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Password",
                                    rules: { required: true },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.passwordChangeParams
                                                      .old_password,
                                                  expression:
                                                    "passwordChangeParams.old_password",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                              attrs: {
                                                type: "password",
                                                placeholder: "Current Password",
                                                name: "old password",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.passwordChangeParams
                                                    .old_password,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.passwordChangeParams,
                                                    "old_password",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "formGroup" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Password",
                                    rules: { required: true, min: 8 },
                                    vid: "new password1",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.passwordChangeParams
                                                      .new_password1,
                                                  expression:
                                                    "passwordChangeParams.new_password1",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                              attrs: {
                                                name: "new password1",
                                                type: "password",
                                                placeholder:
                                                  "TYPE NEW PASSWORD",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.passwordChangeParams
                                                    .new_password1,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.passwordChangeParams,
                                                    "new_password1",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]",
                                                  },
                                                ],
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [
                                                _vm._v(
                                                  "Password must be at least 8 characters"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "formGroup" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Password",
                                    rules: {
                                      required: true,
                                      min: 8,
                                      confirmed: "new password1",
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.passwordChangeParams
                                                      .new_password2,
                                                  expression:
                                                    "passwordChangeParams.new_password2",
                                                },
                                              ],
                                              staticClass:
                                                "formInput formInput--fullWidth",
                                              class: {
                                                hasError: errors.length > 0,
                                              },
                                              attrs: {
                                                name: "new password2",
                                                type: "password",
                                                placeholder:
                                                  "CONFIRM NEW PASSWORD",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.passwordChangeParams
                                                    .new_password2,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.passwordChangeParams,
                                                    "new_password2",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]",
                                                  },
                                                ],
                                                staticClass:
                                                  "formInput__hint formInput__hint--error",
                                              },
                                              [
                                                _vm._v(
                                                  "Password1 and Password2 must be the same"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm.passwordChangeError
                              ? _c("p", { staticClass: "form__error" }, [
                                  _vm._v(_vm._s(_vm.passwordChangeError)),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "form__submit" }, [
                              _c(
                                "button",
                                { staticClass: "btn btn--primary" },
                                [_vm._v("Change Password")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._m(0),
    _c("section", { staticClass: "section sectin" }, [
      _c("div", { staticClass: "container grid grid__col2" }, [
        _c(
          "div",
          {
            staticClass: "wrapper",
            class: {
              "wrapper--empty":
                !_vm.followedProducers || _vm.followedProducers.length === 0,
            },
          },
          [
            !_vm.followedProducers || _vm.followedProducers.length === 0
              ? _c(
                  "div",
                  [
                    _c("h3", { staticClass: "h3" }, [
                      _vm._v("Followed Producers"),
                    ]),
                    _c("p", { staticClass: "p p--lead p--grey" }, [
                      _vm._v("You are not following any producer"),
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn--white",
                        attrs: { to: { name: "Home" } },
                      },
                      [_vm._v("Browse beats")]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("h3", { staticClass: "h3 wrapper__heading" }, [
                      _vm._v("Followed Producers"),
                    ]),
                    _vm._l(_vm.followedProducers, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "followedItem" },
                        [
                          _c("img", {
                            staticClass: "followedItem__logo",
                            attrs: { src: item.producer.image_logo_url },
                          }),
                          _c(
                            "router-link",
                            {
                              staticClass: "followedItem__name",
                              attrs: {
                                to: {
                                  name: "Producer",
                                  params: { slug: item.producer.slug },
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.producer.display_name))]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn--sm btn--primary btn--border followedItem__btn",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleFollowProducer({
                                    producerId: item.producer.id,
                                  })
                                },
                              },
                            },
                            [_vm._v("UNFOLLOW")]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper",
            class: {
              "wrapper--empty":
                !_vm.followedPlaylists || _vm.followedPlaylists.length === 0,
            },
          },
          [
            !_vm.followedPlaylists || _vm.followedPlaylists.length === 0
              ? _c(
                  "div",
                  [
                    _c("h3", { staticClass: "h3" }, [
                      _vm._v("Followed Playlists"),
                    ]),
                    _c("p", { staticClass: "p p--lead p--grey" }, [
                      _vm._v("You are not following any playlist"),
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn--white",
                        attrs: { to: { name: "Genres" } },
                      },
                      [_vm._v("Browse playlists")]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("h3", { staticClass: "h3 wrapper__heading" }, [
                      _vm._v("Followed Playlists"),
                    ]),
                    _vm._l(_vm.followedPlaylists, function (item) {
                      return _c(
                        "div",
                        { staticClass: "followedItem" },
                        [
                          _c("img", {
                            staticClass: "followedItem__logo",
                            attrs: { src: item.playlist.image_thumbnail_url },
                          }),
                          _c(
                            "router-link",
                            {
                              staticClass: "followedItem__name",
                              attrs: {
                                to: {
                                  name: "Playlist",
                                  params: { id: item.playlist.id },
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.playlist.name))]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn--sm btn--primary btn--border followedItem__btn",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleFollowPlaylist(
                                    item.playlist.id
                                  )
                                },
                              },
                            },
                            [_vm._v("UNFOLLOW ")]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-40" }, [
      _c("hr", { staticClass: "divider" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }