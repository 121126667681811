

























































































import { Vue, Component } from 'vue-property-decorator'
import { UserStore } from '@/store/user'
import { IFollowedProducerParams } from '@/api/followed-producers'
import { changePassword } from '~/api/auth'
import { prettifyApiError } from '~/services/prettify-api-error'
import { sendEmailPasswordChanged } from '~/api/email'
import { ModalStore, INotificationParams } from '~/store/modal'
import { updateUserFullName, updateUserEmail } from '~/api/user'

@Component
export default class ProfilePage extends Vue {
	user = { first_name: '', email: '', password: '' }
	updateUserError = ''
	updateUserEmailSuccess = false
	passwordChangeParams: IResetPasswordChangeParams = { old_password: '', new_password1: '', new_password2: '' }
	passwordChangeError = ''

	@UserStore.State('authenticatedUser') authenticatedUser: IUser
	@UserStore.State('followedProducers') followedProducers: IFollowedProducer[]
	@UserStore.State('followedPlaylists') followedPlaylists: IFollowedPlaylist[]

	@ModalStore.Action('showNotification') showNotification: (INotificationParams: INotificationParams) => void
	@UserStore.Action('fetchOwnProfile') fetchOwnProfile: () => void
	@UserStore.Action('toggleFollowProducer') toggleFollowProducer: (IFollowedProducerParams: IFollowedProducerParams) => void
	@UserStore.Action('toggleFollowPlaylist') toggleFollowPlaylist: (playlistId: IPlaylist['id']) => void

	cleanInputs() {
		this.user.password = ''
		this.passwordChangeParams = { old_password: '', new_password1: '', new_password2: '' }
		// @ts-ignore
		this.$refs.observer.reset();
		// this.$validator.reset()
	}

	async changePassword() {
		try {
			await changePassword(this.passwordChangeParams)
			this.showNotification({ name: 'success', title: 'Password Changed', content: 'Your password has been chaned successfully' })
			this.cleanInputs()
			sendEmailPasswordChanged()
		} catch (error) {
			this.passwordChangeError = prettifyApiError(error)
		}
	}

	async updateUserInfo() {
		try {
			if (this.user.first_name !== this.authenticatedUser.first_name) await updateUserFullName(this.user.first_name)
			if (this.user.email !== this.authenticatedUser.email) {
				await updateUserEmail(this.user.email)
				this.updateUserEmailSuccess = true
			}
			// remove errors if any
			this.updateUserError = ''
			this.showNotification({ name: 'success', title: 'Success', content: 'Updated successfully.' })
			// this.fetchOwnProfile()
		} catch (error: any) {
			if (error.response.status === 500) this.updateUserError = error.response.data
			else this.updateUserError = prettifyApiError(error)
		}
	}

	validateForm(scope: any) {
		// this.$validator.validateAll(scope).then(result => {
		// 	if (result) scope === 'form-change-password' ? this.changePassword() : this.updateUserInfo()
		// })
	}

	created() {
		this.user.first_name = this.authenticatedUser.first_name
		this.user.email = this.authenticatedUser.email
	}
}
